import React from "react"
import { Layout, FlexCol, Parallax } from "@components/layout"
import {
  Breadcrumbs,
  ResizedImage as Image,
  AnimatedButton,
} from "@components/shared"

import {
  page__wrapper,
  page__header,
  page__content,
  page__content__btn,
} from "./styles/regulations.module.scss"

const Regulations = ({ pageContext }) => {
  const { page } = pageContext
  return (
    <Layout
      animation={false}
      seo={pageContext.page.seo}
      siteMetaData={pageContext.page.siteMetaData}
    >
      <div>
        <Parallax height={300}>
          <h4>Regulaminy</h4>
        </Parallax>
        <div className={page__wrapper}>
          <div className={page__content}>
            <Breadcrumbs
              elements={[
                {
                  label: page.title,
                  url: `/${page.slug}/`,
                },
              ]}
            />
            <div className="row">
              <AnimatedButton className="page__content__btn">
                <a
                  className={page__content__btn}
                  target="_blank"
                  href="http://gladiatorzy.api.localhost-group.com/wp-content/uploads/2020/07/Regulamin-sklepu-internetowego.pdf"
                  download
                >
                  Pobierz
                </a>
              </AnimatedButton>{" "}
              <h5>Regulamin sklepu</h5>
            </div>

            <div className="row">
              <AnimatedButton className="page__content__btn">
                <a
                  className={page__content__btn}
                  target="_blank"
                  href="http://gladiatorzy.api.localhost-group.com/wp-content/uploads/2020/07/Zgody-na-przetwarzanie-danych-do-regulaminu-checkboxy-Dla-Beneficjentów.pdf"
                  download
                >
                  Pobierz
                </a>
              </AnimatedButton>{" "}
              <h5>Zgoda na przetwarzanie danych osobowych</h5>
            </div>

            <div className="row">
              <AnimatedButton className="page__content__btn">
                <a
                  className={page__content__btn}
                  target="_blank"
                  href="http://gladiatorzy.api.localhost-group.com/wp-content/uploads/2020/07/Obowiązek-informacyjny-RODO-Fundacja-Beneficjenci.pdf"
                  download
                >
                  Pobierz
                </a>
              </AnimatedButton>{" "}
              <h5>Obowiązek informacyjny RODO</h5>
            </div>

            <div className="row">
              <AnimatedButton className="page__content__btn">
                <a
                  className={page__content__btn}
                  target="_blank"
                  href="http://gladiatorzy.api.localhost-group.com/wp-content/uploads/2020/07/polityka-cookies.pdf"
                  download
                >
                  Pobierz
                </a>
              </AnimatedButton>{" "}
              <h5>Polityka plików cookies</h5>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Regulations
